@font-face {
  font-family: "Berlin Sans FB";
  font-style: normal;
  font-weight: normal;
  src: local("Berlin Sans FB"), url(/static/media/BRLNSR.c45332f9.woff) format("woff");
}

@font-face {
  font-family: "Berlin Sans FB Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Berlin Sans FB Bold"), url(/static/media/BRLNSB.dea71057.woff) format("woff");
}
:root {
  --morado: #5932ad;
  --gris: #808080;
  --Background: #ffffff;
  --fontFamily: "'Berlin Sans FB'", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: "'Berlin Sans FB'", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: var(--fontFamily);
  background-color: #ffffff;
  background-color: var(--Background);
  height: 100%;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

div,
img {
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  outline: none;
}

button,
input {
  border: none;
  font-family: "'Berlin Sans FB'", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: var(--fontFamily);
  outline: none;
  transition-timing-function: ease-in-out;
  transition-duration: 0.1s;
}

a {
  text-decoration: none;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: color, border-color, background-color;
  outline: none;
}
a:hover {
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: color, border-color, background-color;
  outline: none;
}
p {
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
}

